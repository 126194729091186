import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";

export const query = graphql`
  query {
    zdjecie: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    szanse: file(relativePath: { eq: "szansesprzedazy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kartaszansy: file(relativePath: { eq: "kartaszansy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    prognozaszansy: file(relativePath: { eq: "prognozaszansy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ZarzadzanieSzansami = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Zarządzanie szansami sprzedaży"
      headline="Spójny proces obsługi leadów"
    >
      <SEO
        title="Zarządzanie szansami sprzedaży"
        description="Zarządzanie szansami sprzedaży w agencji reklamowej i marketingowej ✅ Spójny proces obsługi leadów • Monitorowanie aktywności handlowców • Zapytaj o bezpłatne demo!"
        keywords={["zarządzanie szansami sprzedaży"]}
      />
      <p>
        Empireo wyposażone jest w narzędzia oraz widoki, które znacznie{" "}
        <strong>ułatwiają zarządzanie</strong> różnorodnymi, złożonymi{" "}
        <strong>procesami sprzedażowymi</strong>. A takie właśnie procesy są dla{" "}
        <strong>agencji reklamowej</strong> chlebem powszednim. Empireo oferuje
        Twojej firmie <strong>widok zarządzania szansami sprzedaży</strong>,
        dzięki któremu procesy sprzedaży staną się czytelne i przejrzyste. To
        najlepszy sposób na{" "}
        <strong>efektywne monitorowanie i spójne zarządzanie leadem</strong>.
      </p>

      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.szanse, data.kartaszansy, data.prognozaszansy]}
        alts={[
          "zarządzanie szansami sprzedaży w systemie dla agencji reklamowej, obsługa leadów w systemie dla agencji marketingowej, zarządzanie procesem sprzedaży w marketingu",
        ]}
      />
      <h3>Kluczowe możliwości:</h3>
      <p>
        <ul>
          <li>
            <strong>Samodzielne tworzenie nowych kampanii</strong>, których
            ilość nie jest w żaden sposób ograniczona.
          </li>
          <li>
            <strong>Definiowanie poszczególnych etapów szans</strong>, dzięki
            czemu będziesz w stanie odzwierciedlić wszystkie procesy sprzedażowe
            Twojej agencji reklamowej.
          </li>
          <li>
            <strong>Automatyczne raporty</strong>, czyli prosty sposób na
            analizowanie takich danych jak lejek sprzedaży, jego konwersja czy
            też aktywność handlowców.
          </li>
          <li>
            <strong>Dostępność wszystkich danych w jednym miejscu</strong> -
            ponieważ szanse powiązane są z historią kontaktu z kontrahentem,
            ofertami i zamówieniami.
          </li>
        </ul>
      </p>
      <p
        style={{
          marginTop: "30px",
        }}
      >
        <strong>Szanse sprzedaży</strong> są świetnym sposobem na to, byś zawsze
        wiedział{" "}
        <strong>
          na jakim etapie sprzedaży jesteś z potencjalnymi klientami
        </strong>
        . Przejrzysty, zbiorczy widok informuje o potrzebie{" "}
        <strong>konkretnego działania</strong> z każdym z leadów. Nie ma więc
        mowy o tym, że jakiś klient nie doczeka się obiecanej umowy czy kontaktu
        telefonicznego.
      </p>
    </FunctionPageLayout>
  );
};

export default ZarzadzanieSzansami;
